import React from 'react';
import { Container, Typography} from '@material-ui/core';
import Layout from '../components/layout'
import SEO from '../components/seo'

let year = new Date()

const conductPage = () => (
    <Layout location="">
        <SEO title='Code of Conduct' description='Blix Code of Conduct' />
        <Container fixed style={{paddingTop: '150px', paddingBottom: '50px'}}>
            <Typography color='textPrimary'>Blix Code of Business Conduct and Ethics</Typography>
            <Typography color='textSecondary'>
            <div id='privacy2'>
            <p> Ethical Conduct</p>
            <p>
                 Our ethical standards are high at Blix and we expect our company to be full of interesting, diverse and passionate people who do great work and know how to have fun. Sometimes situations get complex and that’s why we’ve adopted this Code of Business Conduct and Ethics (the “Code”) that you should consult any time you are in doubt about how to act. 

                It’s impossible to predict and describe every situation, so make sure you adopt both the spirit and the letter of the Code. We also need your help to make sure our Code is complied with. If you believe that any Company director, officer, employee, contingent worker or other individual has violated our Code, you should report this to your manager. We strictly prohibit any retaliation against you for making any such report.

                You Are a Part of Blix The Code applies to all of our directors, officers, employees and contingent workers, as well as all directors, officers, employees and contingent workers of our subsidiaries and affiliates. Following the Code entails accepting the Blix values. It is of such importance that a failure to do so can result in disciplinary action, including termination of employment.
                </p>
                <p>Promote a Positive Work Environment </p>
                <p>We are committed to creating a supportive work environment and each employee is expected to create a respectful workplace culture that is free of harassment, intimidation, bias and unlawful discrimination. Blix is an equal opportunity employer and employment is based solely on individual merit and qualifications directly related to professional competence. Blix strictly prohibits discrimination or harassment of any kind on the basis of race, color, religion, creed, sex/gender (including pregnancy, childbirth, breastfeeding and/or related medical conditions), gender identity, gender expression, sexual orientation, marital status, medical condition, military and/or veteran status, national origin, ancestry, mental and/or physical disability, genetic information, request for leave, age or any other characteristics protected by law.
                </p>
                <p>Keep Things Confidential </p>
                <p>As a Blix director, officer, employee or contingent worker, you may have access to Blix’s confidential or proprietary information or the confidential or proprietary information of our customers, partners or other third parties, and it’s imperative that you always protect the confidentiality of such information, during and after your employment or engagement at Blix. 

                If you witness or suspect a particular situation that leads you to believe that confidential information or property of Blix, our partners or customers has been compromised, immediately report this to your manager or the Legal Department. 

                Confidential information includes proprietary information, data, trade secrets and know-how, such as software and product designs, sales figures, revenue data, development plans, customers’ information and data, personal data of customers, candidates or employees, ticket contents, information marked as confidential and your ID, passwords and similar security tools that you use to access computers, data processing resources and facilities. 

                There are “gray areas” in which you will need to apply your best judgment in making sure you don’t disclose any confidential information. Sometimes it may be difficult to determine what is confidential or proprietary information, so when in doubt you should ask your manager or the Legal Department before sharing data with third parties. A good rule of thumb is that all information related to our business and customers should be considered proprietary and confidential unless it has been publicly released.
                </p>
                <p>Protect our Data </p>
                <p>Data protection is an essential element in maintaining the trust that our customers, candidates, and employees have in Blix. Additionally, a variety of increasingly stringent privacy laws, regulations and numerous contracts with customers and other third parties require Blix to protect the security of certain kinds of information. As a Blix employee, you are expected to do your best to protect the privacy and security of all the confidential information you obtain while working at Blix, including our customers’ business information and personal data.
                </p>
                <p>Avoid Conflicts of Interest </p>
                <p>We understand that you may be passionate and involved in outside personal, professional and community activities. We expect you to ensure that these outside activities don’t impair or interfere with the performance of your duties or your ability to act in Blix’s best interests. You are expected to use your judgment to act, at all times and in all ways, in the best interests of Blix and our customers.

                <p>Deal Fairly with Others </p>
                Operating in an honest, fair and ethical manner with others is important to us. Each director, employee, officer and contingent worker should endeavor to deal honestly, fairly and ethically with Blix’s customers, suppliers, competitors and employees. Statements regarding the Company’s products and services must not be untrue, misleading, deceptive or fraudulent. No employee, officer or director should take unfair advantage of anyone through manipulation, concealment, abuse of privileged information, misrepresentation of material facts, or any other unfair dealing practices.
                </p>
                <p> Follow the Law </p>
                <p>We take legal compliance seriously. Each director, officer, employee and contingent worker is required to comply with all applicable laws and consult with the Legal Department in case he or she is unsure about how to act. You are expected to use good judgment and common sense in seeking to comply with all applicable laws, rules and regulations and to ask for advice when you are uncertain about them. 
                </p>
                <p>Anti-Corruption Laws </p>
                <p>Don’t bribe anybody, anytime, for any reason. Remember that giving or accepting anything of value that can improperly affect business decisions qualifies as a bribe. Blix does not permit or condone bribes, kickbacks or other improper payments, transfers or receipts. No director, officer or employee should offer, give, solicit or receive any money or other item of value for the purpose of obtaining, retaining or directing business or bestowing or receiving any kind of favored treatment. 
                </p>
                <p>Competition Laws </p>
                <p>Most countries have laws—known as “antitrust,” “competition,” or “unfair competition” laws— designed to promote free and fair competition. Generally speaking, these laws prohibit arrangements with competitors that restrain trade in some way, abuse intellectual property rights, and use market power to unfairly disadvantage competitors. Certain conduct is absolutely prohibited under these laws and could result in your imprisonment, not to mention severe penalties for Blix. Examples of prohibited conduct include: • agreeing with competitors about prices (price-fixing); • agreeing with competitors to rig bids or to allocate customers or markets; and • agreeing with competitors to boycott a supplier or customer. Other activities can also be illegal or unfair or create the appearance of impropriety. Such activities include: • sharing competitively sensitive information (e.g., prices, costs, market distribution, etc.) with competitors; and • entering into a business arrangement or pursuing a strategy with the sole purpose of harming a competitor. 
                We want to compete fairly. If you feel that any of our actions might violate the above or things just don’t seem right, immediately inform the Legal Department.
                </p>

                <p>Help us improve</p>
                <p>If something is not right, let us know! We strive to be better and maintain the highest level of integrity, not to mention simplicity, beauty and general awesomeness. We trust your common sense and hope that you, as a Blix director, officer, employee or contingent workers, will apply it, alongside with this Code of Ethics, in your everyday judgments. Sometimes situations will not fall within a particular scenario mentioned in this Code of Ethics, or you might see something that does not seem right. When that happens, remember that we are here to help you. Let us know how we can improve. Contact the Legal Department if you have questions that your manager cannot address.




</p>
            

            <p>Last Updated: August 15th, 2019</p>
            <p>© {year.getFullYear()} Blix Inc.</p>
        </div>
        </Typography>
    </Container>
    </Layout>
);

export default conductPage;